var render = function render(){var _vm=this,_c=_vm._self._c;return _c('authentication-form',{staticClass:"register",attrs:{"title":_vm.$t('auth.register.title'),"submit-text":_vm.$t('register')},on:{"submit":_vm.registerUser},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('p',{staticClass:"register__to-login"},[_vm._v(" "+_vm._s(_vm.$t('auth.register.alreadyHaveAccount'))+" ")]),_c('van-button',{attrs:{"type":"hollow-white","to":{ name: 'login' },"text":_vm.$t('login')}})]},proxy:true}])},[_c('van-field',{attrs:{"reference":"email","name":"email","type":"email","label":"Email","rules":[
      { required: true, message: _vm.$t('auth.validation.email.required') },
      { validator: _vm.emailValidator, message: _vm.$t('auth.validation.email.invalid') },
    ],"error":!!_vm.emailError || _vm.email.validateFailed,"error-message":_vm.emailError},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('van-field',{attrs:{"reference":"password","name":"password","type":"password","label":_vm.$t('password'),"placeholder":"****","rules":[
      { required: true, message: _vm.$t('auth.validation.password.required') },
      { validator: _vm.strongPassword, message: _vm.$t('auth.validation.password.insufficient') },
    ],"error":!!_vm.passwordError || _vm.password.validateFailed,"error-message":_vm.passwordError},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('van-field',{staticClass:"register__repeated-password",attrs:{"name":"repeatedPassword","type":"password","label":_vm.$t('passwordRepeat'),"placeholder":"****","rules":[
      { required: true, message: _vm.$t('auth.validation.password.required') },
      { validator: _vm.passwordsMatch, message: _vm.$t('auth.validation.password.mismatch') },
    ]},model:{value:(_vm.repeatedPassword),callback:function ($$v) {_vm.repeatedPassword=$$v},expression:"repeatedPassword"}}),_c('van-field',{staticClass:"register__terms-accepted",attrs:{"name":"termsAccepted","rules":[
      { required: true },
      { validator: _vm.checkTermsAccepted },
    ]},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('van-checkbox',{model:{value:(_vm.termsAccepted),callback:function ($$v) {_vm.termsAccepted=$$v},expression:"termsAccepted"}},[_c('i18n',{attrs:{"path":"acceptTerms","tag":"label"}},[_c('router-link',{attrs:{"to":{ name: 'privacy' }}},[_vm._v(_vm._s(_vm.$t('privacyLabel')))]),_c('router-link',{attrs:{"to":{ name: 'terms' }}},[_vm._v(_vm._s(_vm.$t('termsLabel')))])],1)],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }