<template>
  <authentication-form
    :title="$t('auth.register.title')"
    :submit-text="$t('register')"
    class="register"
    @submit="registerUser"
  >
    <van-field
      v-model="email"
      reference="email"
      name="email"
      type="email"
      label="Email"
      :rules="[
        { required: true, message: $t('auth.validation.email.required') },
        { validator: emailValidator, message: $t('auth.validation.email.invalid') },
      ]"
      :error="!!emailError || email.validateFailed"
      :error-message="emailError"
    />
    <van-field
      v-model="password"
      reference="password"
      name="password"
      type="password"
      :label="$t('password')"
      placeholder="****"
      :rules="[
        { required: true, message: $t('auth.validation.password.required') },
        { validator: strongPassword, message: $t('auth.validation.password.insufficient') },
      ]"
      :error="!!passwordError || password.validateFailed"
      :error-message="passwordError"
    />
    <van-field
      v-model="repeatedPassword"
      name="repeatedPassword"
      type="password"
      class="register__repeated-password"
      :label="$t('passwordRepeat')"
      placeholder="****"
      :rules="[
        { required: true, message: $t('auth.validation.password.required') },
        { validator: passwordsMatch, message: $t('auth.validation.password.mismatch') },
      ]"
    />
    <van-field
      name="termsAccepted"
      class="register__terms-accepted"
      :rules="[
        { required: true },
        { validator: checkTermsAccepted },
      ]"
    >
      <template #input>
        <van-checkbox v-model="termsAccepted">
          <i18n
            path="acceptTerms"
            tag="label"
          >
            <router-link :to="{ name: 'privacy' }">{{ $t('privacyLabel') }}</router-link>
            <router-link :to="{ name: 'terms' }">{{ $t('termsLabel') }}</router-link>
          </i18n>
        </van-checkbox>
      </template>
    </van-field>
    <template #footer>
      <p class="register__to-login">
        {{ $t('auth.register.alreadyHaveAccount') }}
      </p>
      <van-button
        type="hollow-white"
        :to="{ name: 'login' }"
        :text="$t('login')"
      />
    </template>
  </authentication-form>
</template>

<script>
import { Notify } from 'vant';
import { auth, analytics } from '@/firebase';
import { emailValidator, passwordValidator } from '@/validators';
import UserProfile from '@/models/user-profile';
import AuthenticationForm from '@/components/AuthenticationForm/AuthenticationForm.vue';

export default {
  components: {
    AuthenticationForm,
  },
  data() {
    return {
      email: '',
      password: '',
      repeatedPassword: '',
      emailError: '',
      passwordError: '',
      termsAccepted: false,
      termsError: '',
    };
  },
  methods: {
    strongPassword(value) {
      return passwordValidator(value);
    },
    passwordsMatch(value) {
      return value === this.password;
    },
    emailValidator(value) {
      return emailValidator(value);
    },
    checkTermsAccepted(value) {
      return value;
    },
    trackSignupEvent(method) {
      analytics.logEvent('sign_up', {
        method,
        event_category: 'engagement',
        event_label: this.$i18n.t('register'),
      });
    },
    registerUser(values) {
      auth.createUserWithEmailAndPassword(values.email, values.password)
        .then((result) => {
          this.trackSignupEvent(result.additionalUserInfo.providerId);
          new UserProfile(result.user.uid).create({
            onboardingCompleted: false,
          });
          Notify({
            type: 'primary',
            className: 'auth-notification',
            message: this.$i18n.t('auth.signup.success'),
            duration: 5000,
          });
          result.user.sendEmailVerification();
        })
        .catch((error) => {
          if (error.code === 'auth/weak-password') {
            this.passwordError = error.message;
          } else if (error.code === 'auth/email-already-in-use') {
            this.emailError = error.message;
          } else {
            // @todo Production app crash/error reporting.
            console.error(error);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@use '~@/styles/config' as config;

.register__to-login {
  margin-bottom: 1.25em;
  text-align: center;
}

::v-deep .van-checkbox__label {
  color: #fff;
  margin-left: config.$spacing-sm;
}

::v-deep .van-field--error .van-checkbox__label {
  color: config.$color-error-light;
}

::v-deep .van-field__control--custom {
  margin-bottom: config.$spacing-xl;
  border: 0 !important;
}

label a {
  color: config.$color-lighter;
  text-decoration: underline;
}
</style>
